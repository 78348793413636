import { ConfigMurucaAdvancedSearchLayout } from '@net7/boilerplate-muruca';

export default {
  title: 'Ricerca avanzata',
  resultsUrl: { it: '/risultati' },
  hasDynamicOptions: true,
  formConfig: {
    submitButton: {
      label: 'advancedsearch#submit',
    },
    resetButton: {
      label: 'advancedsearch#reset',
    },
    groups: [
      {
        id: 'group-global-search',
        sections: ['section-global'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_global',
          isOpen: true,
          showHeader: false,
        },
      },
      {
        id: 'group-opere',
        sections: ['section-opere'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_opere',
          isOpen: false,
          showHeader: true,
        },
      },
      {
        id: 'group-manoscritti',
        sections: ['section-manoscritti'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_manoscritti',
          isOpen: true,
          showHeader: true,
        },
      },
    ],
    sections: [
      {
        id: 'section-global',
        title: '',
        inputs: [
          {
            id: 'query-fulltext',
            type: 'text',
            data: {
              id: 'query-fulltext',
              label: 'advancedsearch#fulltext_text',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'resource-type',
            type: 'checkbox',
            label: 'advancedsearch#label_resource-type',
            state: {
              value: [
                'work',
                'manuscript',
              ],
            },
            data: {
              id: 'resource-type',
              classes: 'checkbox-query-fulltext',
              legend: '',
              checkboxes: [
                {
                  label: 'advancedsearch#label_work',
                  payload: 'work',
                  classes: 'checkbox-query-fulltext'
                },
                {
                  label: 'advancedsearch#label_manuscript',
                  payload: 'manuscript',
                  classes: 'checkbox-query-fulltext',

                },
              ],
            },
          },
        ],
      },
      {
        id: 'section-opere',
        title: '',
        inputs: [
          {
            id: 'query-title',
            type: 'text',
            data: {
              id: 'query-title',
              label: 'advancedsearch#label_title',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-editor',
            type: 'text',
            data: {
              id: 'query-editor',
              label: 'advancedsearch#label_editor',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'work_genere',
            type: 'select',
            data: {
              id: 'work_genere',
              label: 'advancedsearch#label_genere',
              payload: '',
              options: [
              ]
            }
          },
          {
            id: 'work_lingua',
            type: 'select',
            data: {
              id: 'work_lingua',
              label: 'advancedsearch#label_lingua',
              payload: '',
              options: [
              ]
            }
          },
          {
            id: 'work_forma',
            type: 'select',
            data: {
              id: 'work_forma',
              label: 'advancedsearch#label_forma',
              payload: '',
              options: [
              ]
            }
          },
          {
            id: 'query-data',
            type: 'text',
            data: {
              id: 'query-data',
              label: 'advancedsearch#label_data',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-edition',
            type: 'text',
            data: {
              id: 'query-edition',
              label: 'advancedsearch#label_edition',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
        ],
      },
      {
        id: 'section-manoscritti',
        title: '',
        inputs: [
          {
            id: 'query-cnmd',
            type: 'text',
            data: {
              id: 'query-cnmd',
              label: 'advancedsearch#label_cnmd',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'manuscript_tipo',
            type: 'select',
            data: {
              id: 'manuscript_tipo',
              label: 'advancedsearch#label_tipo',
              payload: '',
              options: [
              ]
            }
          },
          {
            id: 'query-unita-codicologica',
            type: 'text',
            data: {
              id: 'query-unita-codicologica',
              label: 'advancedsearch#label_unita',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
        ],
      },
    ],
  },
} as ConfigMurucaAdvancedSearchLayout;
