// import { Routes } from '@angular/router';

import {
  // COMMON
  Page404LayoutComponent,
} from '@net7/boilerplate-common';
// MURUCA
import {
  MrHomeLayoutComponent,
  MrSearchLayoutComponent,
  MrStaticLayoutComponent,
  MrResourceLayoutComponent,
  // MrTimelineLayoutComponent,
  MrAdvancedSearchLayoutComponent,
  MrAdvancedResultsLayoutComponent,
  // OTHER
  // DynamicPathGuard,
  // MrPostsLayoutComponent,
  MrItineraryLayoutComponent,
  LocaleDependenciesGuard,
} from '@net7/boilerplate-muruca';

type RouteConfig = {
  component?: any;
  paths: {
    [locale: string]: string;
  };
  data?: any;
  isRedirect?: boolean;
};

const NOT_FOUND_PATH = 'page-404';
const config: {
  [layoutID: string]: RouteConfig;
} = {

  // Ricerca manoscritti
  biblioteca: {
    paths: { 
      it: 'biblioteca', 
      en: 'en/library' 
    },
    component: MrSearchLayoutComponent,
    data: { configId: 'search-manuscript' },
  },

  // Manoscritto (TABS)
  manuscript: { // questo da togliere ?
    paths: { 
      it: 'manoscritto/:id/:slug', 
      en: 'en/manuscript/:id/:slug' 
    },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-manuscript' },
  },
  contents: {
    paths: { 
      it: 'manoscritto/:id/:slug/contents', 
      en: 'en/manuscript/:id/:slug/contents' 
    },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-manuscript-contents' },
  },
  details: {
    paths: { 
      it: 'manoscritto/:id/:slug/detail-sheet', 
      en: 'en/manuscript/:id/:slug/detail-sheet' 
    },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-manuscript-detail-sheet' },
  },
  bibliography: {
    paths: { 
      it: 'manoscritto/:id/:slug/bibliography', 
      en: 'en/manuscript/:id/:slug/bibliography' 
    },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-manuscript-bibliography' },
  },
  info: {
    paths: { 
      it: 'manoscritto/:id/:slug/cataloging-info', 
      en: 'en/manuscript/:id/:slug/cataloging-info' 
    },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-manuscript-cataloging-info' },
  },
  where: {
    paths: { 
      it: 'manoscritto/:id/:slug/where-to-find', 
      en: 'en/manuscript/:id/:slug/where-to-find' 
    },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-manuscript-where-to-find' },
  },

  // Percorsi
  itinerary: {
    paths: { 
      it: 'percorsi/:id/:slug', 
      en: 'en/itinerary/:id/:slug' 
    },
    component: MrItineraryLayoutComponent,
    data: { configId: 'itinerary' },
  },

  // Ricerca opere
  opere: {
    paths: { 
      it: 'opere', 
      en: 'en/works' },
    component: MrSearchLayoutComponent,
    data: { configId: 'search-works' },
  },
  // Opera
  work: {
    paths: { 
      it: 'opera/:id/:slug', 
      en: 'en/work/:id/:slug' },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-work' },
  },
  
  // Ricerca avanzata
  search: {
    paths: { 
      it: 'ricerca-avanzata', 
      en: 'en/advanced-search' 
    },
    component: MrAdvancedSearchLayoutComponent,
    data: { configId: 'advanced-search' },
  },
  search_results: {
    paths: { 
      it: 'risultati', 
      en: 'en/results' 
    },
    component: MrAdvancedResultsLayoutComponent,
    data: { configId: 'advanced-results' },
  },
  
  // 
  search_tools: {
    paths: { 
      it: 'materiali_strumenti', 
      en: 'en/tools' 
    },
    component: MrSearchLayoutComponent,
    data: { configId: 'search-tools' },
  },
  tools: {
    paths: { 
      it: 'strumenti/:id/:slug', 
      en: 'en/tools/:id/:slug' 
    },
    component: MrResourceLayoutComponent,
    data: { configId: 'resource-tools' },
  },
  bibliografia: {
    paths: { 
      it: 'bibliografia', 
      en: 'en/bibliography' 
    },
    component: MrSearchLayoutComponent,
    data: { configId: 'search-bibliografia' },
  },
  page: {
    component: MrStaticLayoutComponent,
    paths: {
      it: ':slug',
      en: 'en/:slug',
    },
    data: { configId: 'page' },
  },
  home: {
    component: MrHomeLayoutComponent,
    paths: { it: '', en: 'en/' },
    data: { configId: 'home' },
  },
  homeRedirect: { paths: { en: 'en' }, isRedirect: true },
  page404: {
    paths: { it: 'page-404', en: 'en/page-404' },
    component: Page404LayoutComponent,
    data: { id: 'page-404' },
  }
  
};

const APP_ROUTES: any = [
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'manoscritto/:id/:slug',
    redirectTo: 'manoscritto/:id/:slug/contents',
    pathMatch: 'full',
  },
];

/**
 * Generate angular routes from config
 */
Object.keys(config).forEach((routeId) => {
  const { component, data, paths, isRedirect } = config[routeId];
  Object.entries(paths).forEach(([locale, path]) => {
    // path to component
    if (component) {
      APP_ROUTES.push({
        path,
        component,
        data: { ...data, routeId, locale },
        canActivate: [LocaleDependenciesGuard],
      });
    }
    // catch route
    if (isRedirect) {
      APP_ROUTES.push({ path, redirectTo: `${path}/` });
    }
  });
});

// default route handler
APP_ROUTES.push(
  {
    path: '**',
    component: MrStaticLayoutComponent,
    data: {
      notFoundPath: NOT_FOUND_PATH,
      locale: 'it',
    },
    canActivate: [LocaleDependenciesGuard],
  },
  {
    path: NOT_FOUND_PATH,
    component: Page404LayoutComponent,
    data: { id: 'page-404', locale: 'it' },
    canActivate: [LocaleDependenciesGuard],
  }
);

export { APP_ROUTES };
